import { v4 as uuidV4 } from 'uuid'
import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import { emptyArr, uniq } from 'util/arrays'
import { sortByKey } from 'util/arrays/sorting'
import { isFunction, isNotFunction } from 'util/functions'
import { denormalize } from 'normalizr'
import debug from 'util/debug'
import { createBasicSelector } from 'util/redux'
import entitySchemas from './schema'
import { buildEntityLookup } from './utils'

export const selectEntities = state => state.entities || emptyObj

const entityDependancyLookup = buildEntityLookup(Object.values(entitySchemas))

export const selectCurrentEntitiesStore = createBasicSelector(
  selectEntities,
  entities => entities.current || emptyObj
)

export const selectCurrentEntitiesNormalizrStore = createSelector(
  selectCurrentEntitiesStore,
  currentEntitiesStore => {
    return Object.keys(currentEntitiesStore).reduce(
      (normalizrStore, entityType) => {
        Object.assign(normalizrStore, {
          [entityType]: currentEntitiesStore[entityType].byId,
        })
        return normalizrStore
      },
      {}
    )
  }
)

const selectStoreEntitiesById = createBasicSelector(
  selectEntities,
  (_state, store) => store,
  (_state, _store, type) => type,
  (entities, store, type) => entities[store]?.[type]?.byId || emptyObj
)

const createDynamicCacheKey = dependancies => {
  return () => dependancies.join(',')
}

export function createDynamicCachedSelector(inputDependancies) {
  const dependancies = inputDependancies
  const selectors = []

  dependancies.forEach(dependancyEntityType => {
    selectors.push(state => {
      if (!dependancyEntityType) {
        return emptyObj
      }

      return selectStoreEntitiesById(state, 'current', dependancyEntityType)
    })
  })

  selectors.push((...args) => {
    return dependancies.reduce((normalizrStore, eType) => {
      const entityIndex = dependancies.indexOf(eType)
      Object.assign(normalizrStore, {
        [eType]: args[entityIndex],
      })
      return normalizrStore
    }, {})
  })

  // eslint-disable-next-line no-param-reassign
  return createCachedSelector(...selectors)(createDynamicCacheKey(dependancies))
}

const normalizrSelectorsByEntity = Object.keys(entityDependancyLookup).reduce(
  (lookup, entityType) => {
    const dependancies = entityDependancyLookup[entityType]
    // eslint-disable-next-line no-param-reassign
    lookup[entityType] = createDynamicCachedSelector(dependancies)
    return lookup
  },
  {}
)

export const selectCurrentEntitiesNormalizrStoreByEntityType = createBasicSelector(
  state => state,
  (_state, _store, entityType) => entityType,
  (state, entityType) => {
    return normalizrSelectorsByEntity[entityType](state)
  }
)

const selectStoreEntities = createCachedSelector(
  selectStoreEntitiesById,
  entitiesById => Object.values(entitiesById)
)((_state, _store, type) => type)

const selectStoreEntitiesSortedBy = createCachedSelector(
  selectStoreEntities,
  (_state, _store, _type, key) => key,
  (_state, _store, _type, _key, direction) => direction,
  (entities, sortKey, sortDirection) =>
    sortByKey(entities, sortKey, { direction: sortDirection })
)((_state, _store, _type, key, direction) => `${key}-${direction}`)

const selectStoreEntityIds = createBasicSelector(
  selectEntities,
  (_state, store) => store,
  (_state, _store, type) => type,
  (entities, store, type) => entities[store]?.[type]?.ids || emptyArr
)

const selectStoreEntityById = createCachedSelector(
  selectStoreEntitiesById,
  selectCurrentEntitiesNormalizrStoreByEntityType,
  (_state, _store, type) => type,
  (_state, _store, _type, entityId) => entityId,
  (_state, _store, _type, _entityId, isLoadedFn) => isLoadedFn,
  (_state, _store, _type, _entityId, _isLoadedFn, shouldDenormalize) =>
    shouldDenormalize,
  (
    entitiesById,
    currentEntitiesNormalizrStore,
    entityType,
    entityId,
    isLoadedFn,
    shouldDenormalize = false
  ) => {
    const normalizedEntity = entitiesById[entityId]
    if (!normalizedEntity) return null

    const entity = !shouldDenormalize
      ? normalizedEntity
      : denormalize(
          [normalizedEntity],
          [entitySchemas[entityType]],
          currentEntitiesNormalizrStore
        )[0]

    if (isNotFunction(isLoadedFn)) return entity

    return isLoadedFn(entity) ? entity : null
  }
)((_state, _store, type, entityId, isLoadedFn, shouldDenormalize = false) => {
  const hasIsLoadedFn = isFunction(isLoadedFn)

  const isLoadedFnPrototype = hasIsLoadedFn && Object.getPrototypeOf(isLoadedFn)
  // eslint-disable-next-line no-underscore-dangle
  if (hasIsLoadedFn && !isLoadedFnPrototype.__entityIdentifier) {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    isLoadedFnPrototype.__entityIdentifier = uuidV4()
  }
  return `${type}-${entityId}-${shouldDenormalize}-${
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    hasIsLoadedFn ? isLoadedFnPrototype.__entityIdentifier : 'none'
  }`
})

const selectStoreEntitiesByIds = createCachedSelector(
  selectStoreEntitiesById,
  (_state, _store, _type, entityIds) => entityIds,
  (entitiesById, entityIds) => {
    if (!entityIds) return []
    const entities = []
    const uniqueEntityIds = uniq(entityIds)

    uniqueEntityIds.forEach(entityId => {
      const entity = entitiesById[entityId]
      if (entity) entities.push(entity)
    })

    return entities
  }
)(
  (_state, store, type, entityIds) =>
    `${type}-${store}-${uniq(entityIds || []).join(',')}`
)

export const selectCurrentEntitiesDenormalizedByType = createCachedSelector(
  (state, entityType) =>
    selectCurrentEntitiesNormalizrStoreByEntityType(
      state,
      'current',
      entityType
    ),
  (_state, entityType) => entityType,
  (currentEntitiesNormalizrStore, entityType) => {
    if (!entitySchemas[entityType]) {
      debug(
        `Unable to denormalize, entityType ${entityType} was not found in the default export of ducks/entities/schema. PLease add it to fix error below!`
      )
    }
    return Object.values(
      denormalize(
        Object.keys(currentEntitiesNormalizrStore[entityType] || []),
        [entitySchemas[entityType]],
        currentEntitiesNormalizrStore
      )
    )
  }
)((_state, entityType) => entityType || 'unknown')

export const selectCurrentEntitiesById = (state, type) => {
  return selectStoreEntitiesById(state, 'current', type)
}

export const selectCurrentEntities = (state, type) => {
  return selectStoreEntities(state, 'current', type)
}

export const selectCurrentEntitiesSortedBy = (
  state,
  type,
  sortKey,
  sortDirection
) => {
  return selectStoreEntitiesSortedBy(
    state,
    'current',
    type,
    sortKey,
    sortDirection
  )
}

export const selectCurrentEntityIds = (state, type) => {
  return selectStoreEntityIds(state, 'current', type)
}

export const selectCurrentEntityById = (
  state,
  type,
  entityId,
  isLoadedFn,
  shouldDenormalize
) => {
  return selectStoreEntityById(
    state,
    'current',
    type,
    entityId,
    isLoadedFn,
    shouldDenormalize
  )
}

export const selectCurrentEntitiesByIds = (state, type, entityIds) => {
  return selectStoreEntitiesByIds(state, 'current', type, entityIds)
}

export const selectPendingEntitiesById = (state, type) => {
  return selectStoreEntitiesById(state, 'pending', type)
}

export const selectPendingEntities = (state, type) => {
  return selectStoreEntities(state, 'pending', type)
}

export const selectPendingEntitiesSortedBy = (
  state,
  type,
  sortKey,
  sortDirection
) => {
  return selectStoreEntitiesSortedBy(
    state,
    'pending',
    type,
    sortKey,
    sortDirection
  )
}

export const selectPendingEntityIds = (state, type) => {
  return selectStoreEntityIds(state, 'pending', type)
}

export const selectPendingEntityById = (state, type, entityId, isLoadedFn) => {
  return selectStoreEntityById(state, 'pending', type, entityId, isLoadedFn)
}

export const selectPendingEntitiesByIds = (state, type, entityIds) => {
  return selectStoreEntitiesByIds(state, 'pending', type, entityIds)
}

export const selectEntityById = (state, type, entityId, store, isLoadedFn) => {
  return selectStoreEntityById(state, store, type, entityId, isLoadedFn)
}

/*
// Usefull during debugging
window.selectCurrentEntitiesById = selectCurrentEntitiesById
window.selectCurrentEntities = selectCurrentEntities
window.selectCurrentEntityIds = selectCurrentEntityIds
window.selectCurrentEntityById = selectCurrentEntityById
window.selectPendingEntitiesById = selectPendingEntitiesById
window.selectPendingEntities = selectPendingEntities
window.selectPendingEntityIds = selectPendingEntityIds
window.selectPendingEntityById = selectPendingEntityById
window.selectPendingEntitiesByIds = selectPendingEntitiesByIds
window.selectCurrentEntitiesByIds = selectCurrentEntitiesByIds
*/
