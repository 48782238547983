export const AGENT_DATA_TABLE_QUERY_ID =
  'entityType:agent includeAll:true pageSize:10000'
export const AGENT_ACTIVE_OR_ARCHIVED_DATA_TABLE_QUERY_ID =
  'entityType:agent includeArchived:true pageSize:10000'

export const LEGACY_AGENT_ROLE_OWNER = 'owner'
export const LEGACY_AGENT_ROLE_ADMIN = 'admin'
export const LEGACY_AGENT_ROLE_AGENT = 'agent'
export const LEGACY_AGENT_ROLE_VIEWER = 'viewer'

export const AGENT_ROLE_OWNER = 'OWNER'
export const AGENT_ROLE_ADMIN = 'ADMIN'
export const AGENT_ROLE_AGENT = 'AGENT'
export const AGENT_ROLE_VIEWER = 'VIEWER'

export const PAID_AGENT_ROLES = [
  AGENT_ROLE_OWNER,
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_AGENT,
]

export const ROLE_TO_NAME = {
  [AGENT_ROLE_OWNER]: 'Owner',
  [AGENT_ROLE_ADMIN]: 'Admin',
  [AGENT_ROLE_AGENT]: 'Agent',
  [AGENT_ROLE_VIEWER]: 'Lite',
}
