import * as types from 'constants/action_types'
import { v4 as uuidV4 } from 'uuid'
import { normalizeAttachment } from 'util/file'
import { sizeInBytes } from 'util/strings'

import {
  UPDATE_DRAFT,
  CREATE_DRAFT,
  STORED_DRAFT,
  DELETE_DRAFT,
  FAILED_SEND,
  DRAFT_RECIPIENT_SYNC_FINISHED,
  DRAFT_RECIPIENT_SYNC_STARTED,
  FETCH_DRAFTS_SUCCESS,
  DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
} from 'ducks/drafts2/constants'
import {
  isDraftBodySizeWithinLimit,
  DRAFT_BODY_SIZE_EXCEED_MAX_LIMIT_MSG,
} from 'util/draft'
import { omit } from 'util/objects'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { getRawId, isGid } from 'util/globalId'
import { createActionTypeReducer } from 'util/reducers'
import { FETCH_CONTACT_PAGE_SUCCESS } from 'ducks/crm/contacts/types'

const isSendableTwitter = draft => {
  const bodyIsEmpty = !draft.body || draft.body === ''
  if (
    draft.twitterRecipients &&
    draft.twitterRecipients.filter(r => r.twitter && r.twitter.username)
      .length > 0 &&
    !bodyIsEmpty
  ) {
    return { isSendable: true }
  }
  return { isSendable: false }
}

const isSendableAndReason = draft => {
  if (draft.isTwitter) return isSendableTwitter(draft)

  const bodyIsEmpty = !draft.body || draft.body === ''
  const isNote = draft.isNote
  if (!isNote && !draft.to) return { isSendable: false }
  if (!isNote && draft.to.length === 0) return { isSendable: false }

  const hasAttachments =
    draft.attachmentsNormalized && draft.attachmentsNormalized.length > 0
  if (bodyIsEmpty && !draft.isForwarding && !hasAttachments)
    return { isSendable: false }

  if (!isDraftBodySizeWithinLimit(draft.bodySizeBytes)) {
    return {
      isSendable: false,
      notSendableReason: DRAFT_BODY_SIZE_EXCEED_MAX_LIMIT_MSG,
    }
  }

  return { isSendable: true }
}

const defaultState = {
  byTicketId: {},
  byMessageId: {},
  byId: {},
  currentRecipientSync: undefined,
}

const reducers = {}

const updateDraftInStore = (
  draftState,
  draftType,
  id,
  fields,
  ticketId,
  messageId
) => {
  const draft = draftState.byId[id] || {}
  const currentVersion = (draft && draft.version) || 1
  const version = currentVersion + 1
  Object.assign(draft, {
    id,
    ...fields,
    ticketId,
    messageId,
    version,
  })
  draft.bodySizeBytes = sizeInBytes(draft.body)

  const { isSendable, notSendableReason } = isSendableAndReason(draft)
  draft.isSendable = isSendable
  draft.notSendableReason = notSendableReason

  draftState.byTicketId[ticketId][draftType] = id
  draftState.byId[id] = draft
  return draftState
}

const putDraftInStore = (
  state,
  draftType,
  id,
  version,
  fields,
  ticketId,
  messageId,
  serverVersion
) => {
  const newDraft = {
    id,
    ...fields,
    ticketId,
    messageId,
    version,
    serverVersion,
  }
  newDraft.bodySizeBytes = sizeInBytes(newDraft.body)

  const { isSendable, notSendableReason } = isSendableAndReason(newDraft)
  newDraft.isSendable = isSendable
  newDraft.notSendableReason = notSendableReason

  const currentTicketStore = state.byTicketId[ticketId] || {}
  return {
    ...state,
    byTicketId: {
      ...state.byTicketId,
      [ticketId]: {
        ...currentTicketStore,
        [draftType]: id,
      },
    },
    byId: {
      ...state.byId,
      [id]: newDraft,
    },
  }
}

const deleteDraftInStore = (state, ticketId, draftId) => {
  const { byId, byTicketId } = state
  const newById = { ...byId }
  const newByTicketId = { ...byTicketId }
  const draft = byId[draftId]
  const draftType = draft.draftType
  const ticketStore = newByTicketId[ticketId] || {}
  delete newById[draftId]

  return {
    ...state,
    byTicketId: {
      ...newByTicketId,
      [ticketId]: omit([draftType], ticketStore),
    },
    byId: newById,
  }
}

reducers[CREATE_DRAFT] = (state, action) => {
  const { draftId, draftType, fields, ticketId, messageId } = action.payload
  const id = draftId || uuidV4()

  return putDraftInStore(state, draftType, id, 1, fields, ticketId, messageId)
}

reducers[FAILED_SEND] = (state, action) => {
  const { draft } = action.payload
  return putDraftInStore(
    state,
    draft.draftType,
    draft.id,
    draft.version,
    draft,
    draft.ticketId,
    draft.messageId,
    draft.serverVersion
  )
}

reducers[UPDATE_DRAFT] = (draftState, action) => {
  const { draftId: id, draftType, fields, ticketId, messageId } = action.payload

  return updateDraftInStore(
    draftState,
    draftType,
    id,
    fields,
    ticketId,
    messageId
  )
}

reducers[DELETE_DRAFT] = (state, action) => {
  const { draftId, ticketId } = action.payload

  return deleteDraftInStore(state, ticketId, draftId)
}

reducers[STORED_DRAFT] = (state, action) => {
  const { draftId, serverVersion } = action.payload
  const { byId } = state
  const draft = byId[draftId]

  return {
    ...state,
    byId: {
      ...state.byId,
      [draftId]: { ...draft, serverVersion },
    },
  }
}

reducers[types.ATTACHMENT_UPLOAD_FINISHED] = (state, action) => {
  const { ticketId, draftId, file, url, key, expiringUrl } = action.data

  if (draftId === undefined) return state
  if (draftId === null) return state

  const messageId = null
  const { byId } = state
  const draft = byId[draftId]

  if (!draft) return state

  const attachments = draft.attachments || {}

  attachments[file.name] = {
    url,
    expiringUrl,
    fileName: file.name,
    fileSize: file.size,
    contentType: file.type,
    key,
  }

  const fields = {
    attachments,
    attachmentsNormalized: Object.values(attachments).map(normalizeAttachment),
  }

  return updateDraftInStore(
    state,
    draft.draftType,
    draftId,
    fields,
    ticketId,
    messageId
  )
}

reducers[types.DELETE_ATTACHMENT] = (state, action) => {
  const { ticketId, draftId, file } = action.data

  const messageId = null
  if (draftId === undefined) return state
  if (draftId === null) return state

  const { byId } = state
  const draft = byId[draftId]

  if (!draft) return state

  const attachments = draft.attachments || {}

  delete attachments[file.file_name]

  const fields = {
    attachments,
    attachmentsNormalized: Object.values(attachments).map(normalizeAttachment),
  }

  return updateDraftInStore(
    state,
    draft.draftType,
    draftId,
    fields,
    ticketId,
    messageId
  )
}

reducers[DRAFT_RECIPIENT_SYNC_STARTED] = (state, { payload }) => {
  return {
    ...state,
    currentRecipientSync: {
      source: payload.source,
    },
  }
}

reducers[DRAFT_RECIPIENT_SYNC_FINISHED] = state => {
  return {
    ...state,
    currentRecipientSync: undefined,
  }
}

reducers[FETCH_DRAFTS_SUCCESS] = (state, action) => {
  const { drafts: { nodes = [] } = {} } = action?.payload || {}

  if (isNullOrUndefined(nodes) || !Array.isArray(nodes)) return state

  let newState = state
  const messageId = null

  nodes.forEach(draft => {
    let ticketId = getRawId(draft.conversationId)

    if (
      ticketId?.toString() === DRAFT_NEW_CONVERSATION_CONVERSATION_ID.toString()
    ) {
      ticketId = 'new'
    }

    try {
      const parsed = JSON.parse(draft.payload)
      const version = draft.version
      parsed.version = version
      newState = putDraftInStore(
        newState,
        draft.draftType.toLowerCase(),
        draft.draftId,
        version,
        parsed,
        ticketId,
        messageId,
        version
      )
    } catch (e) {
      // pass
    }
  })

  return newState
}

reducers[FETCH_CONTACT_PAGE_SUCCESS] = (draftState, action) => {
  if (!action?.transformedEntities?.contacts) return draftState
  const contactsByEmail = Object.values(
    action.transformedEntities.contacts
  ).reduce((byEmail, contact) => {
    // eslint-disable-next-line no-param-reassign
    byEmail[contact.email] = contact
    return byEmail
  }, {})

  Object.keys(draftState.byId).forEach(draftId => {
    const draft = draftState.byId[draftId]

    const toId = draft.to?.[0]?.id
    const toEmail = draft.to?.[0]?.email
    const toContactId = draft.to?.[0]?.contact_id

    if (isGid(toId) || !toEmail || !!toContactId || !contactsByEmail[toEmail])
      return

    draft.to[0].contact_id = contactsByEmail[toEmail].id
  })
  return draftState
}

export default createActionTypeReducer(reducers, defaultState)
