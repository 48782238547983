export const BASIC_TEMPLATE_FIELDS = `
  agents {
    nodes {
      id
    }
  }
  conditions {
    nodes {
      id
      param
      operator
      value
      source {
        __typename
        ... on CustomField {
          id
          key
        }
      }
    }
  }
  createdAt
  default
  displayCountWhenInactive
  hideIfZeroConversations
  id
  locked
  matchType
  name
  position
  state
  type
  teams {
    nodes {
      id
    }
  }
  channels {
    nodes {
      id
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
  }
  updatedAt

`

export const query = folderId => `
  query FolderQuery {
    folders(filter: { ids: ["${folderId}"] }) {
      nodes {
        ${BASIC_TEMPLATE_FIELDS}
      }
    }
  }
`

export const collectionQuery = () => `
  query FoldersQueryV2($size: Int) {
    folders(first: $size) {
      nodes {
        ${BASIC_TEMPLATE_FIELDS}
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

export const dataTableQuery = () => `
  query FoldersDataTableQueryV2($type: FolderType, $size: Int) {
    folders(first: $size, filter: { state: ALL, scope: ALL, type: $type }) {
      nodes {
        ${BASIC_TEMPLATE_FIELDS}
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
    channels(first: 1000) {
      nodes {
        id
        name
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
    agents(first: 1000) {
      nodes {
        id
        name
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
    teams(first: 1000) {
      nodes {
        id
        name
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

export const getByIdsQuery = () => `
  query FoldersByIdsQueryV2($filter: FolderFilter, $cursor: String, $orderBy: FolderOrder) {
    folders(filter: $filter, after: $cursor, orderBy: $orderBy ) {
      nodes {
        ${BASIC_TEMPLATE_FIELDS}
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
    agents(first: 1000) {
      nodes {
        id
        name
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

export const getChannelsQuery = () => `
  query FoldersByIdsQueryV2($filter: FolderFilter, $cursor: String, $channelsCursor: String, $orderBy: FolderOrder) {
    folders(filter: $filter, after: $cursor, orderBy: $orderBy ) {
      nodes {
        channels(after: $channelsCursor) {
          nodes {
            id
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`
