import { useEffect, useMemo, useState } from 'react'
import emailParser from 'email-addresses'
import { queryParams } from 'util/params'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDraftDefaultByTicketId,
  selectDraftIdByTicketId,
} from 'ducks/drafts2/selectors'
import { doSearchRecipients } from 'ducks/drafts2/operations'
import { doUpsertReplyDraft } from 'ducks/drafts2/operations/doUpsertReplyDraft'
import { selectCurrentContact } from 'ducks/crm/contacts'
import { selectRequestByType } from 'ducks/requests/selectors'

import { fetchDraftsActionRequestKey } from './util'
import {
  DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
  REQUEST_DRAFT_TYPES,
} from './constants'

export function useQueryParamToRecipientLoader(draftId) {
  const [fetching, setFetching] = useState(true)
  const [toRecipient, setToRecipient] = useState(null)
  const dispatch = useDispatch()

  const currentContact = useSelector(selectCurrentContact)

  const toEmail = useMemo(() => {
    const params = queryParams()
    if (!params.to) return null

    const parsed = emailParser.parseAddressList({
      input: params.to,
      rejectTLD: true,
    })

    if (parsed && parsed[0]) {
      const item = parsed[0]
      return item.address
    }

    return null
  }, [])

  useEffect(
    () => {
      const updateRecipients = async () => {
        if (!toEmail) {
          setFetching(false)
          return
        }

        const contactEmail = currentContact?.email

        let foundRecipient

        if (contactEmail && contactEmail === toEmail) {
          foundRecipient = currentContact
        } else {
          const recipients = await dispatch(doSearchRecipients(toEmail))
          foundRecipient = (recipients?.contacts || []).find(recipient => {
            return recipient.email === toEmail
          })
        }

        if (foundRecipient) {
          setToRecipient(foundRecipient)
        }

        setFetching(false)
      }
      updateRecipients()
    },
    [dispatch, setFetching, draftId, currentContact, toEmail]
  )

  return {
    loading: fetching,
    toRecipient,
    toEmail,
  }
}

export const useDraft = (ticketId, type) => {
  const [draftsLoaded, setDraftsLoaded] = useState(false)
  const dispatch = useDispatch()
  const draftId = useSelector(state =>
    selectDraftIdByTicketId(state, ticketId, type)
  )

  const {
    loading: loadingToRecipient,
    toEmail,
  } = useQueryParamToRecipientLoader(draftId)

  const requestKey = fetchDraftsActionRequestKey(
    DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
    REQUEST_DRAFT_TYPES[type]
  )

  const serverDraftLoaded = useSelector(state =>
    selectRequestByType(state, requestKey)
  ).loaded

  const loaded = !loadingToRecipient && serverDraftLoaded && draftsLoaded

  const draftDefaults = useSelector(state =>
    selectDraftDefaultByTicketId(state, ticketId, type)
  )

  useEffect(
    () => {
      if (loadingToRecipient || !serverDraftLoaded) return

      if (draftId && !toEmail) {
        // use existing draft
        setDraftsLoaded(true)
        return
      }

      dispatch(doUpsertReplyDraft(draftId, type, ticketId, null, draftDefaults))
      setDraftsLoaded(true)
    },
    [
      dispatch,
      draftId,
      loadingToRecipient,
      serverDraftLoaded,
      draftsLoaded,
      type,
      toEmail,
      draftDefaults,
      ticketId,
    ]
  )

  return {
    ticketId,
    draftId,
    loaded,
  }
}
