/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import {
  queryIdToQuery,
  queryStringToQueryId,
  constructFolderItemQueryId,
} from 'ducks/searches/utils/query'
import { CHANNEL_STATE } from 'ducks/mailboxes/constants'
import { buildId } from 'util/globalId'
import { compact, withToggle } from 'util/arrays'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import {
  LOG_CONVERSATION_PAGE,
  NEW_CONVERSATION_PAGE,
  SEARCH_PAGE,
  TICKET_COMMENT_PAGE,
  TICKET_PAGE,
  TICKETS_PAGE,
} from 'constants/pages'
import {
  SET_COLLECTIONS_BYID,
  TOGGLE_COLLECTION,
  OPEN_ONE_COLLECTION,
  BUILD_FROM_WIDGETS,
  BUILD_INBOX_MENU_FROM_MAILBOXES,
} from '../actionTypes/collections'
import {
  mapChannelTypeToGlobalIdType,
  mapChannelTypeToPageType,
} from '../utils'

// Example of how the collections look in state
/*
{
  fc0: {
    id: 'fc0',
    type: 'pinned',
    name: 'Pinned',
    position: 1,
    queryId: 'inbox:4517239960',
    color: '#e5bb3e',
    hideName: false,
    icon: null,
    linkTo: null,
  }
}
*/

const collectionsByIdInitialState = {}

function buildCollectionQueryId(channel, folder, orderBy) {
  return constructFolderItemQueryId({ channel, folder, orderBy })
}

export const collectionsById = createActionTypeReducer(
  {
    [SET_COLLECTIONS_BYID]: (_, { payload: { collections = [] } }) => {
      return collections.reduce((collectionById, collection) => {
        collectionById[collection.id] = collection
        return collectionById
      }, {})
    },
    [BUILD_FROM_WIDGETS]: (
      draftState,
      {
        payload: {
          widgets = [],
          folders = [],
          prefersAllMailboxesSectionVisible = false,
          prefersUnifiedInbox = false,
          channelType: pageChannelType,
          orderBy,
        },
      }
    ) => {
      const validIds = []
      const hasAccessToAtleastOneWidget = widgets.some(w => w.hasAccess)

      const allowedWidgets = prefersUnifiedInbox
        ? []
        : [...widgets.filter(w => w.hasAccess)]
      const allowedWidgetsByPageType = allowedWidgets.filter(
        w => w.channelType === pageChannelType
      )

      if (
        hasAccessToAtleastOneWidget &&
        (prefersUnifiedInbox ||
          (prefersAllMailboxesSectionVisible &&
            allowedWidgetsByPageType.length > 1))
      ) {
        allowedWidgets.unshift({
          id: '',
          name: pageChannelType === 'widget' ? 'All widgets' : 'All social',
          hasAccess: true,
          channelType: pageChannelType,
        })
      }

      allowedWidgets.forEach((widget, index) => {
        const {
          id: widgetId,
          name,
          channelColor,
          kind: widgetKind,
          channelType,
        } = widget
        const currentPosition = index + 1
        const id = buildId(
          mapChannelTypeToGlobalIdType(channelType || pageChannelType),
          widgetId
        )
        validIds.push(id)
        const collectionQueryId = buildCollectionQueryId(
          {
            id: widgetId,
            channelType,
          },
          folders[0],
          orderBy
        )
        const queryId = widgetId ? `widget:${widgetId}` : ''
        if (!draftState[id]) {
          draftState[id] = {}
        }
        Object.assign(draftState[id], {
          id,
          channelId: widgetId,
          channelKind: widgetKind,
          type: channelType,
          queryId,
          name,
          position: currentPosition,
          color: channelColor || 'var(--color-monochrome-dark)',
          hideName: false,
          icon: false,
          warning: false,
          linkTo: {
            type: mapChannelTypeToPageType(channelType),
            payload: {},
            meta: {
              query: queryIdToQuery(collectionQueryId),
            },
          },
        })
      })
      Object.keys(draftState).forEach(id => {
        if (!validIds.includes(id)) {
          delete draftState[id]
        }
      })
      return draftState
    },
    [BUILD_INBOX_MENU_FROM_MAILBOXES]: (
      draftState,
      {
        payload: {
          mailboxes = [],
          folders = [],
          prefersAllMailboxesSectionVisible = false,
          prefersUnifiedInbox = false,
          channelType: pageChannelType,
          orderBy,
        },
      }
    ) => {
      const confirmedMailboxStates = [
        CHANNEL_STATE.ACTIVE,
        CHANNEL_STATE.CONFIRMED,
        CHANNEL_STATE.SYNCING,
        CHANNEL_STATE.DEMO,
      ]
      const validIds = []
      // Note the BUILD_INBOX_MENU_FROM_MAILBOXES pre-filters the mailboxes
      // to accessible mailboxes only
      const hasAccessToAtleastOneChannel = mailboxes.length > 0

      const allowedChannels = prefersUnifiedInbox ? [] : [...mailboxes]
      if (
        hasAccessToAtleastOneChannel &&
        (prefersUnifiedInbox ||
          (prefersAllMailboxesSectionVisible && allowedChannels.length > 1))
      ) {
        allowedChannels.unshift({
          gid: '',
          name: `All ${app.t('Mailboxes')}`,
          hasAccess: true,
          channelType: pageChannelType,
          state: CHANNEL_STATE.ACTIVE,
        })
      }

      allowedChannels.forEach((channel, index) => {
        const {
          gid: channelId,
          name,
          color: channelColor,
          channelType: channelKind,
        } = channel
        const currentPosition = index + 1
        const id = buildId(
          mapChannelTypeToGlobalIdType(pageChannelType),
          channelId
        )
        validIds.push(id)
        const collectionQueryId = buildCollectionQueryId(
          {
            id: channelId,
            channelType: pageChannelType,
          },
          folders[0],
          orderBy
        )
        const queryId = compact([
          channelId ? `channel:${channelId}` : null,
          'is:open',
          `type:${pageChannelType}`,
        ]).join(' ')
        if (!draftState[id]) {
          draftState[id] = {}
        }
        Object.assign(draftState[id], {
          id,
          channelId,
          channelKind,
          type: pageChannelType,
          queryId,
          name,
          position: currentPosition,
          color: channelColor || 'var(--color-monochrome-dark)',
          hideName: false,
          icon: false,
          warning: !confirmedMailboxStates.includes(channel.state),
          linkTo: {
            type: mapChannelTypeToPageType(pageChannelType),
            payload: {},
            meta: {
              query: queryIdToQuery(collectionQueryId),
            },
          },
        })
      })
      Object.keys(draftState).forEach(id => {
        if (!validIds.includes(id)) {
          delete draftState[id]
        }
      })
      return draftState
    },
  },
  collectionsByIdInitialState
)

const openCollectionIdsInitialState = []

const currentOpenCollectionIdromQueryReducer = (draftState, action) => {
  // https://github.com/faceyspacey/redux-first-router/blob/master/docs/query-strings.md#where-query-lives-on-your-actions
  const { meta: { location: { current: { query = {} } = {} } = {} } = {} } =
    action || {}

  const { channel = [], folder, tagid, assignee } =
    queryIdToQuery(queryStringToQueryId(query)) || {}

  if (channel.length > 0 || folder || tagid || assignee) {
    return [channel[0] || '']
  }
  return draftState
}

export const openCollectionIds = createActionTypeReducer(
  {
    [CHAT_PAGE]: currentOpenCollectionIdromQueryReducer,
    [SOCIAL_PAGE]: currentOpenCollectionIdromQueryReducer,
    [SEARCH_PAGE]: currentOpenCollectionIdromQueryReducer,
    [TICKETS_PAGE]: currentOpenCollectionIdromQueryReducer,
    [TICKET_PAGE]: currentOpenCollectionIdromQueryReducer,
    [TICKET_COMMENT_PAGE]: currentOpenCollectionIdromQueryReducer,
    [LOG_CONVERSATION_PAGE]: currentOpenCollectionIdromQueryReducer,
    [NEW_CONVERSATION_PAGE]: currentOpenCollectionIdromQueryReducer,
    [TOGGLE_COLLECTION]: (draftState, { payload: { collectionId } }) => {
      withToggle(draftState, collectionId)
    },
    [OPEN_ONE_COLLECTION]: (_, { payload: { collectionId } }) => {
      return [collectionId]
    },
  },
  openCollectionIdsInitialState
)
