import { selectCurrentChannels } from 'ducks/channels/selectors'
import { selectCustomFields } from 'ducks/crm/customFields/selectors/base'
import { createSelector } from 'reselect'
import { selectIsCustomFieldsEnabled } from 'selectors/app/selectAccountPreferences'
import { emptyArr, flatten, uniq } from 'util/arrays'

export const selectChannelCustomFields = createSelector(
  selectCurrentChannels,
  selectCustomFields,
  selectIsCustomFieldsEnabled,
  (channels, customFields, isCustomFieldsEnabled) => {
    if (!isCustomFieldsEnabled) return emptyArr
    return uniq(
      flatten(
        channels.map(c => {
          return customFields
            .filter(cf => !cf.deletedAt)
            .filter(cf => !!c.customFields?.includes(cf.id))
        })
      )
    )
  }
)
