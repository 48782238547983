import {
  doApiWriteRequest,
  doApiReadRequest,
  doGraphqlRequest,
} from 'ducks/requests/operations'
import {
  collectionQuery,
  generateUpdateMutation,
  DESTROY_MUTATION,
  allChannelFields,
  channelQuery,
  doCreateAndFetchChannel,
} from 'ducks/mailboxes/api'
import { selectCurrentChannels } from 'ducks/channels/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { convertHexToRGBA } from 'util/colors'
import { buildId, getRawId, buildIdFromAny } from 'util/globalId'
import { FETCH_CHANNELS } from 'ducks/channels/actionTypes'
import {
  changeEntity,
  entityAdditionalActionToActions,
  mergeEntityChanges,
} from 'ducks/entities/actionUtils'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import storage from 'util/storage'

import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { channel, idAttributeGidToId } from 'ducks/entities/schema'
import { SETTINGS_CHANNEL_TABLE_ID } from 'ducks/tables/ids'
import { selectRequestByType } from 'ducks/requests/selectors'
import { camelize } from 'util/strings'
import { selectQueryParamsQueryId, selectIsInInbox } from 'selectors/location'
import { batchActions } from 'util/redux'
import { doShowSnackbar } from 'actions/snackbar'
import { doOpenMainPage } from 'actions/pages'
import { selectPreviousDrawer } from 'ducks/drawers/selectors'
import {
  queryIdToQuery,
  queryStringToQueryId,
} from 'ducks/searches/utils/query'
import { selectCurrentConversations } from 'ducks/tickets/selectors'
import { buildConversationOptimistDeleteOptions } from 'ducks/tickets/utils/optimistic'

import {
  GMAIL_IMPORT,
  SEND_VERIFICATION,
  SEND_SETUP_INSTRUCTIONS,
  UPDATE_MAILBOX,
  FETCH_MAILBOX,
  TEST_SMTP,
  TEST_IMAP,
  CHECK_DNS,
  DESTROY_MAILBOX,
  SMTP_CHECK,
  REMOVE_MAILBOX_LOCALLY,
} from './actionTypes'
import {
  mailboxGraphQlResponseSchema,
  mailboxUpdateGraphQlResponseSchema,
  mailboxDeleteGraphQlResponseSchema,
} from './schema'
import { EMAIL_SERVER_DOMAIN } from './constants'
import { selectCurrentMailboxId } from './selectors/selectCurrentMailboxId'

export const LOAD_ALL_MAILBOXES_QUERYID =
  'entityType:channel pageSize:10000 type:inboxes'

export const doGmailImport = (
  id,
  {
    import_labels: importLabels = false,
    close_tickets: importCloseTickets = false,
    pages_to_import: pagesToImport = 1,
    stop_importing_at: stopImportingAt,
  } = {},
  options = {}
) => async dispatch => {
  const payload = {
    mailbox_id: id,
    close_tickets: importCloseTickets,
    process_labels: importLabels,
    pages_to_import: pagesToImport,
    stop_importing_at: stopImportingAt,
  }

  return dispatch(
    doApiWriteRequest(GMAIL_IMPORT, 'api/gmail_imports', payload, {
      ...options,
      throwOnError: true,
      method: 'POST',
    })
  )
}

export const doSmtpCheck = id => dispatch => {
  return dispatch(
    doApiWriteRequest(SMTP_CHECK, `api/mailboxes/${id}/delivery_checks`, {
      throwOnError: true,
      method: 'POST',
    })
  )
}

const throwErrorIfVerifyIMAPFails = (response, inComingEmailServer) => {
  if (!response.imap?.success || !response.smtp?.success) {
    let errorCode = 'ERR_AUTH_IMAP'
    const { responseText } = response.imap || {}
    if (/Invalid credentials/i.test(responseText)) {
      errorCode = 'ERR_IMAP_CREDENTIALS'
    } else if (
      /app.*password/i.test(responseText) &&
      inComingEmailServer[EMAIL_SERVER_DOMAIN].includes('gmail.com')
    ) {
      errorCode = 'ERR_IMAP_APP_PASSWORD'
    }
    throw new Error(errorCode)
  }
}

// Verify imap and smtp credentials before creating a mailbox
export const doVerifyIMAP = (
  { inComingEmailServer, outgoingEmailServer },
  options
) => async dispatch => {
  const oauthTest = storage.get('testOAuthWindowOverride') || {}
  if (oauthTest.skipEmailOauth) {
    return { imap: { success: true }, smtp: { success: true } }
  }
  const response = await dispatch(
    doApiWriteRequest(
      TEST_IMAP,
      'v1/verify_imap',
      {
        imap: inComingEmailServer,
        smtp: outgoingEmailServer,
      },
      {
        ...options,
        throwOnError: true,
        method: 'POST',
      }
    )
  )
  throwErrorIfVerifyIMAPFails(response, inComingEmailServer)
  return response
}

// Verify imap and smtp credentials after creating a mailbox
export const doVerifyMailboxIMAP = (
  mailboxId,
  {
    incoming_email_server: inComingEmailServer,
    outgoing_email_server: outgoingEmailServer,
  },
  options
) => async dispatch => {
  const response = await dispatch(
    doApiWriteRequest(
      TEST_IMAP,
      `v1/mailboxes/${mailboxId}/verify_imap`,
      {
        imap: inComingEmailServer,
        smtp: outgoingEmailServer,
      },
      {
        ...options,
        throwOnError: true,
        method: 'PUT',
      }
    )
  )
  throwErrorIfVerifyIMAPFails(response, inComingEmailServer)
  return response
}

export const doSendVerification = (id, options) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      SEND_VERIFICATION,
      `v1/mailboxes/${id}/send_forwarding_verification`,
      {},
      {
        ...options,
        throwOnError: true,
        method: 'POST',
      }
    )
  )
}

export const doSendSetupInstructions = (id, payload, options) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      SEND_SETUP_INSTRUCTIONS,
      `v1/mailboxes/${id}/send_forwarding_instructions`,
      payload,
      {
        ...options,
        throwOnError: true,
        method: 'POST',
      }
    )
  )
}

export function doFetchMailboxes(options = {}) {
  const { requestType = FETCH_CHANNELS, variables } = options
  const queryId = LOAD_ALL_MAILBOXES_QUERYID
  const cursor = 'all'
  return (dispatch, getState) => {
    const agentId = selectCurrentUserGlobalId(getState())
    return dispatch(
      doGraphqlRequest(
        requestType,
        collectionQuery(),
        {
          ...variables,
          agentId,
        },
        {
          normalizationSchema: mailboxGraphQlResponseSchema,
          searches: {
            queryId,
            cursor,
            idAttribute: idAttributeGidToId,
          },
          throwOnError: true,
          app: true,
          meta: {
            mergeEntities: true,
          },
        }
      )
    )
  }
}

export const doFetchMailboxesInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_MAILBOXES_QUERYID,
  entityType: 'channel',
  doLoadAllFn: doFetchMailboxes,
})

function calculateMailboxPosition(mailbox, currentOrderedMailboxes) {
  if (currentOrderedMailboxes[mailbox.position + 1]) {
    return currentOrderedMailboxes[mailbox.position + 1].position - 10
  } else if (currentOrderedMailboxes[mailbox.position - 1]) {
    return currentOrderedMailboxes[mailbox.position - 1].position + 10
  }
  return mailbox.position
}

export const doUpdateMailbox = (id, mailbox, options = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    responseFields = allChannelFields({ loadFullCustomFields: true }),
    updatePosition = false,
    queryId,
    orderedIds = null,
    rebuildMenu = true,
  } = options
  const orderedChannels = selectCurrentChannels(state)

  const mailboxInput = {
    ...mailbox,
    channelId: buildId('Channel', id),
    permittedAgentIds: mailbox.permittedAgentIds?.map(agentId =>
      buildIdFromAny('Agent', agentId)
    ),
    permittedTeamIds: mailbox.permittedTeamIds?.map(teamId =>
      buildIdFromAny('Team', teamId)
    ),
  }
  delete mailboxInput.forward_email_address
  delete mailboxInput.id
  const optimisticMailbox = {
    ...mailbox,
  }

  optimisticMailbox.user_ids = mailbox.permittedAgentIds
  optimisticMailbox.group_ids = mailbox.permittedTeamIds

  delete optimisticMailbox.permittedAgentIds
  delete optimisticMailbox.permittedTeamIds
  if (mailboxInput.color && mailboxInput.color.startsWith('#')) {
    mailboxInput.color = convertHexToRGBA(mailboxInput.color)
  }
  if (updatePosition) {
    optimisticMailbox.position = calculateMailboxPosition(
      mailbox,
      orderedChannels
    )
  }

  const entityId = idAttributeGidToId({ id: getRawId(id) })
  const entityType = 'channel'

  const optimist = {
    entities: {
      [entityType]: {
        [entityId]: optimisticMailbox,
      },
    },
  }

  const agentId = selectCurrentUserGlobalId(getState())
  const response = await dispatch(
    doGraphqlRequest(
      UPDATE_MAILBOX,
      generateUpdateMutation(responseFields),
      {
        input: mailboxInput,
        agentId,
      },
      {
        normalizationSchema: mailboxUpdateGraphQlResponseSchema,
        throwOnError: true,
        app: true,
        optimist,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [entityType],
              phases: ['SUCCESS'],
            },
            {
              type: 'UPDATE_CURSOR',
              queryId,
              entityIds: orderedIds,
              phases: ['STARTED'],
            },
          ],
        },
        ...options,
      }
    )
  )

  if (rebuildMenu) {
    dispatch(doBuildInboxMenuFromMailboxes())
  }

  return response
}

export const doTestMailboxSmtp = (
  { id, username, password, authentication, address, port, useSsl },
  options = {}
) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      TEST_SMTP,
      `api/settings/mailboxes/test_smtp`,
      {
        smtp: {
          id,
          user_name: username,
          password,
          authentication,
          address,
          port,
          use_ssl: useSsl ? '1' : '0',
        },
      },
      {
        ...options,
        throwOnError: true,
        method: 'POST',
      }
    )
  )
}

export const doCheckMailboxDns = (
  mailboxId,
  { email, validate },
  options = {}
) => dispatch => {
  return dispatch(
    doApiReadRequest(
      CHECK_DNS,
      `api/settings/mailboxes/${mailboxId}/dns_check`,
      { email, validate },
      {
        ...options,
        throwOnError: true,
        method: 'GET',
      }
    )
  )
}

export const doDeleteMailbox = (gid, options = {}) => dispatch => {
  return dispatch(
    doGraphqlRequest(
      DESTROY_MAILBOX,
      DESTROY_MUTATION,
      {
        id: buildIdFromAny('Channel', gid),
      },
      {
        app: true,
        throwOnError: true,
        normalizationSchema: mailboxDeleteGraphQlResponseSchema,
        includeLegacyPayload: true,
        transformResponse: () => {
          return {
            // HACK (jscheel): API doesn't return anything and legacy handlers
            // expect a raw scatterswapped id
            id: getRawId(gid),
            gid,
          }
        },
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['channel'],
              phases: ['SUCCESS'],
            },
          ],
        },
        ...options,
        moduleOptions: {
          ...options.moduleOptions,
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                entityType: 'channel',
                entityId: gid,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doFetchMailboxesAndBuildInboxMenu = () => async dispatch => {
  // Should fech mailboxes in case new folders are added
  await dispatch(doFetchMailboxes())
  // we have this instead of after doCreateSingleFolder/doUpdateSingleFolder on drawers because we do not sync
  // CREATE_FOLDER_SUCCESS & UPDATE_FOLDER_SUCCESS with state.leftnav.folders & state.foldersV2.byId (too cumbersome due to difference in data and structure)
  // Easier to maintain by just rebuilding. Also keep in mind this datagrid's FETCH_FOLDERS_V2_SUCCESS syncs with state.foldersV2.byId
  // and we need that updated data to rebuild leftnav
  dispatch(doBuildInboxMenuFromMailboxes())
}

export function doFetchMailbox(
  id,
  { skipLoaded = true, shouldRebuildMenu = false } = {}
) {
  return async (dispatch, getState) => {
    const state = getState()
    const agentId = selectCurrentUserGlobalId(state)
    const channelId = buildId('Channel', getRawId(id))
    const requestKey = `${FETCH_MAILBOX}_${channelId}`
    const request = selectRequestByType(state, requestKey)

    if (skipLoaded && request.loading) return null

    // eslint-disable-next-line consistent-return
    const response = await dispatch(
      doGraphqlRequest(
        FETCH_MAILBOX,
        channelQuery,
        {
          id: channelId,
          agentId,
        },
        {
          normalizationSchema: channel,
          throwOnError: true,
          app: true,
          meta: {
            requestKey: camelize(requestKey),
          },
          transformResponse: data => {
            return data?.node
          },
        }
      )
    )
    if (shouldRebuildMenu) {
      dispatch(doBuildInboxMenuFromMailboxes())
    }

    return response
  }
}

export function doCreateMailbox(mailbox) {
  return async (dispatch, getState) => {
    const state = getState()
    // needed to update the inboxes data table (if mounted) with newly created mailbox
    const rawQueryId = selectQueryParamsQueryId(state, {
      targetId: SETTINGS_CHANNEL_TABLE_ID,
    })
    const queryObject = queryIdToQuery(rawQueryId)
    // The mailbox maybe is created on chats page, need to ensure we add the mailbox id to the inboxes data table
    const queryId =
      queryObject && queryObject?.type !== 'inboxes'
        ? queryStringToQueryId({
            ...queryObject,
            type: 'inboxes',
          })
        : rawQueryId

    const newMailbox = await dispatch(doCreateAndFetchChannel(mailbox, queryId))
    return newMailbox
  }
}

export function doRemoveMailboxLocally(id) {
  return (dispatch, getState) => {
    const state = getState()
    const currentMailboxId = selectCurrentMailboxId(state)
    const isInInbox = selectIsInInbox(state)
    const isInDrawer = !!selectPreviousDrawer(state)
    const mailboxTickets = selectCurrentConversations(state).filter(
      c => c.channel === id
    )
    const entityChanges = [changeEntity('channel', id, {}, 'remove')]
    mailboxTickets.forEach(ticket => {
      const { additionalActions } = buildConversationOptimistDeleteOptions(
        getState,
        ticket.id
      )
      entityAdditionalActionToActions('STARTED', additionalActions).forEach(
        entityChange => {
          entityChanges.push(entityChange)
        }
      )
    })

    const actions = [
      {
        type: REMOVE_MAILBOX_LOCALLY,
        data: {
          id,
        },
        ...mergeEntityChanges(entityChanges),
      },
      doBuildInboxMenuFromMailboxes(),
    ]
    if (currentMailboxId === id && !isInDrawer) {
      // We don't want to show the snackbar or redirect to the main page
      // if the demo mailbox is deleted during the mailbox creation
      actions.unshift(doShowSnackbar(`Current ${app.t('mailbox')} deleted`))
      if (isInInbox) {
        dispatch(doOpenMainPage())
      }
    }
    return dispatch(batchActions(actions))
  }
}
