import { selectWidgetsWithChat } from 'ducks/widgets/selectors'
import { selectCurrentQueryOrderByOrDefault } from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import {
  queryIdToQuery,
  defaultFolderItemQueryId,
  convertLegacyFormat,
} from 'ducks/searches/utils/query'
import { queryParams } from 'util/params'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectPrefersAllMailboxesSectionVisible } from 'ducks/currentUser/selectors/preferences/selectPrefersAllMailboxesSectionVisible'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { selectCurrentAgents } from 'ducks/agents/selectors'
import {
  selectCurrentPage,
  selectCurrentPayload,
  selectCurrentSearch,
  selectQueryParams,
} from 'selectors/location'
import {
  selectAccessibleCurrentEmailChannels,
  selectChannelTypeFromUrl,
} from 'ducks/channels/selectors'
import { getRawId, buildId } from 'util/globalId'
import selectCurrentConversationId from 'ducks/searches/selectors/selectCurrentConversationId'
import { selectCurrentTeamsById } from 'ducks/teams/selectors'
import { SEARCH_PAGE } from 'constants/pages'
import { selectIsOnSearchPageWithLegacySearch } from 'ducks/location/selectors'
import storage from 'util/storage'
import { isChatChannelType } from 'ducks/channels/channelTypes'
import { omit } from 'util/objects'
import {
  BUILD_FROM_WIDGETS,
  TOGGLE_COLLECTION,
  OPEN_ONE_COLLECTION,
  BUILD_INBOX_MENU_FROM_MAILBOXES,
} from '../actionTypes/collections'
import { selectTicketFolders } from '../selectors/folders/selectTicketFolders'
import { selectChatFolders } from '../selectors/folders/selectChatFolders'
import { mapChannelTypeToPageType } from '../utils'
import { FOLDER_STATE, FOLDER_TYPE } from '../constants'
import { selectCurrentFolders } from '../selectors/folders'

export function doToggleCollection({ collectionId, event }) {
  return dispatch => {
    if (queryParams().channelId === collectionId) {
      event.preventDefault()
    }
    return dispatch({
      type: TOGGLE_COLLECTION,
      payload: {
        collectionId,
      },
    })
  }
}

export function doOpenOneCollection({ collectionId, event }) {
  return dispatch => {
    if (queryParams().channelId === collectionId) {
      event.preventDefault()
    }
    return dispatch({
      type: OPEN_ONE_COLLECTION,
      payload: {
        collectionId,
      },
    })
  }
}

export function doBuildMenuFromWidgets({ autoRedirect = true } = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const widgets = selectWidgetsWithChat(state)
    const folders = selectChatFolders(state)
    const currentQueryId = selectCurrentQueryId(state)
    const prefersAllMailboxesSectionVisible = selectPrefersAllMailboxesSectionVisible(
      state
    )
    const prefersUnifiedInbox = selectPrefersUnifiedInbox(state)
    const currentChannelType = selectChannelTypeFromUrl(state)
    const orderBy = selectCurrentQueryOrderByOrDefault(state)

    dispatch({
      type: BUILD_FROM_WIDGETS,
      payload: {
        widgets,
        folders,
        prefersAllMailboxesSectionVisible,
        prefersUnifiedInbox,
        channelType: currentChannelType,
        orderBy,
      },
    })
    // Kevin R: I would have preferred dispatching BUILD_FROM_WIDGETS
    // and the doRedirectToCollectionAndFolderById in a batch action
    // but for some reason the redux first router doesnt play well with
    // out batching system
    if (!currentQueryId && autoRedirect) {
      dispatch(
        doRedirectToCollectionAndFolderById(null, null, { ignoreLast: true })
      )
    }
  }
}

export function doRedirectToCollectionAndFolderById(
  inputCollectionId = null,
  inputFolderId = null,
  {
    preserveQuery = true,
    preservePage = false,
    ignoreLast = false,
    channelType: inputChannelType,
    query: additionalQuery = {},
  } = {}
) {
  return (dispatch, getState) => {
    const lastMailboxQueryId = storage.get('lastMailboxQueryId')
    const lastQuery =
      queryIdToQuery(lastMailboxQueryId, { csvArrays: true }) || {}
    const { channel: lastChannelId, folder: lastFolderId } = lastQuery
    const collectionId =
      inputCollectionId || (!ignoreLast ? lastChannelId : null)
    const folderId = inputFolderId || (!ignoreLast ? lastFolderId : null)

    const state = getState()
    const currentPageQuery = selectQueryParams(state)

    const currentChannelType =
      inputChannelType || selectChannelTypeFromUrl(state)
    const isInChat = isChatChannelType(currentChannelType)
    const currentPage = selectCurrentPage(state)
    const currentPayload = selectCurrentPayload(state)

    // Load the data required
    const allWidgets = selectWidgetsWithChat(state)
    const allMailboxes = selectAccessibleCurrentEmailChannels(state)
    const allFolders = selectCurrentFolders(state)

    // Filter based on the input parameters
    const filteredWidgets = allWidgets.filter(
      w =>
        (collectionId === null || getRawId(collectionId) === w.id) &&
        w.channelType === currentChannelType
    )
    const filteredMailboxes = allMailboxes.filter(
      m => collectionId === null || getRawId(collectionId) === m.id
    )
    const filteredFolders = allFolders.filter(
      f =>
        f.state === FOLDER_STATE.ACTIVE &&
        (isInChat
          ? f.type === FOLDER_TYPE.CHAT
          : f.type === FOLDER_TYPE.TICKET) &&
        (folderId === null || folderId === f.id)
    )

    // If the filter yields no results, it indicates that the mailbox, widget or folder
    // has been removed or the user doesnt have access. In this event we fallback to
    // all available options and have the defaultFolderItemQueryId logic select a collection
    // and folder
    const widgets = filteredWidgets.length > 0 ? filteredWidgets : allWidgets
    const mailboxes =
      filteredMailboxes.length > 0 ? filteredMailboxes : allMailboxes
    const folders = filteredFolders.length > 0 ? filteredFolders : allFolders

    const channels = isInChat ? widgets : mailboxes

    const prefersAllMailboxesSectionVisible = selectPrefersAllMailboxesSectionVisible(
      state
    )
    const prefersUnifiedInbox = selectPrefersUnifiedInbox(state)
    const orderBy = selectCurrentQueryOrderByOrDefault(state)

    if (channels.length === 0 || folders.length === 0) return
    const query = {
      ...queryIdToQuery(
        defaultFolderItemQueryId({
          channels,
          folders,
          prefersAllMailboxesSectionVisible,
          prefersUnifiedInbox,
          pageChannelType: currentChannelType,
          orderBy,
        })
      ),
    }
    const conversationId = selectCurrentConversationId(state)
    if (conversationId) {
      query.conversationId = conversationId
    }
    const { channelType } =
      widgets.find(w => buildId('Widget', w.id) === query.channel) || {}
    const currentPageType = mapChannelTypeToPageType(
      inputChannelType || channelType || currentChannelType
    )

    const cleanedCurrentPageQuery = omit(
      ['channel', 'folder'],
      currentPageQuery
    )
    dispatch({
      type: preservePage ? currentPage : currentPageType,
      payload: preservePage ? currentPayload : {},
      meta: {
        query: {
          ...(preserveQuery ? cleanedCurrentPageQuery : {}),
          ...query,
          ...additionalQuery,
        },
      },
    })
  }
}

export function doRedirectLegacySearch() {
  return (dispatch, getState) => {
    const state = getState()
    const currentSearch = selectCurrentSearch(state)
    const decodedCurrentSearch =
      currentSearch && decodeURIComponent(currentSearch)
    if (!decodedCurrentSearch) {
      dispatch(
        doRedirectToCollectionAndFolderById(null, null, {
          ignoreLast: true,
          forceRedirect: true,
        })
      )
    } else {
      const query = {
        ...queryIdToQuery(convertLegacyFormat(decodedCurrentSearch)),
        type: 'mailbox',
      }

      dispatch({
        type: SEARCH_PAGE,
        payload: {},
        meta: {
          query: {
            ...query,
          },
        },
      })
    }
  }
}

export function doBuildInboxMenuFromMailboxes({
  autoRedirect = false,
  forceRedirect = false,
  ignoreLast = true,
} = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const currentQueryId = selectCurrentQueryId(state)
    const mailboxes = selectAccessibleCurrentEmailChannels(state)
    const folders = selectTicketFolders(state)
    const agents = selectCurrentAgents(state)
    const currentUser = selectCurrentUser(state)
    const teamsById = selectCurrentTeamsById(state)
    const prefersAllMailboxesSectionVisible = selectPrefersAllMailboxesSectionVisible(
      state
    )
    const prefersUnifiedInbox = selectPrefersUnifiedInbox(state)
    const orderBy = selectCurrentQueryOrderByOrDefault(state)
    const isOnSearchPageWithLegacySearch = selectIsOnSearchPageWithLegacySearch(
      state
    )
    const { channel, folder } =
      queryIdToQuery(currentQueryId, {
        csvArrays: true,
      }) || {}

    const missingMailbox =
      channel && !mailboxes.find(m => m.id === getRawId(channel))
    const missingFolder = folder && !folders.find(f => f.id === folder)

    const hasMissingEntity = missingMailbox || missingFolder

    const requiresRedirect =
      autoRedirect &&
      // If we're on a page with a ticket list and there is no query id we need to calculate
      // a valid query id and redirect to it as this is wahat the ticket list uses to load data
      (!currentQueryId ||
        // The calling function has indicated that we always want to redirect regardless if we're
        // already on a query id. This happens when clearning searches or redirecting from a
        // settings drawer to the folder
        forceRedirect ||
        // This indicates that the user has a url with a channel or folder id which doesnt exist
        // or they dont have access to. In this case we also need to redirect them so that they dont
        // get an empty state
        hasMissingEntity)

    dispatch({
      type: BUILD_INBOX_MENU_FROM_MAILBOXES,
      payload: {
        folders,
        mailboxes,
        agents,
        currentUser,
        channelType: 'mailbox',
        prefersAllMailboxesSectionVisible,
        prefersUnifiedInbox,
        orderBy,
        teamsById,
      },
    })

    if (requiresRedirect) {
      if (isOnSearchPageWithLegacySearch) {
        dispatch(doRedirectLegacySearch())
      } else {
        dispatch(
          doRedirectToCollectionAndFolderById(null, null, {
            ignoreLast,
            forceRedirect,
            preserveQuery: !hasMissingEntity,
          })
        )
      }
    }
  }
}
